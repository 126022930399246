// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.features-slider {
    position: relative;
}

.slider-container {
    display: flex;
    align-items: center;
}

.slide {
    display: flex;
    align-items: center;
    width: 100%;
    transition: transform 0.5s ease;
}

.feature-image {
    width: 25%;
    margin: auto;
    margin-right: 20px;
}

.description {
    width: 60%;
    margin-right: 5%;
}

.slide_arrow {
    width: 30px;
    height: 30px;
    border-color: #000;
    position: absolute;
    top: 50%;
    margin-top: -31px
}

.prev {
    border-bottom: 6px solid;
    border-left: 6px solid;
    transform: rotate(45deg);
    left: 10px
}


.next {
    border-bottom: 6px solid;
    border-left: 6px solid;
    transform: rotate(-135deg);
    right: 10px
}


@media screen and (max-width:1030px) {
    .feature-div {
        height: 400px;
        margin-bottom: 120px;
    }

    .description {
        font-size: 14px;
    }
}


@media screen and (max-width:767px) {
    .feature-div {
        height: 300px;
        margin-bottom: 80px;
    }

    .description {
        font-size: 12px;
    }
}

@media screen and (max-width:550px) {
    .feature-div {
        height: 240px;
    }

    .description {
        font-size: 8px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Styles/feature_slider.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,+BAA+B;AACnC;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,QAAQ;IACR;AACJ;;AAEA;IACI,wBAAwB;IACxB,sBAAsB;IACtB,wBAAwB;IACxB;AACJ;;;AAGA;IACI,wBAAwB;IACxB,sBAAsB;IACtB,0BAA0B;IAC1B;AACJ;;;AAGA;IACI;QACI,aAAa;QACb,oBAAoB;IACxB;;IAEA;QACI,eAAe;IACnB;AACJ;;;AAGA;IACI;QACI,aAAa;QACb,mBAAmB;IACvB;;IAEA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,cAAc;IAClB;AACJ","sourcesContent":[".features-slider {\n    position: relative;\n}\n\n.slider-container {\n    display: flex;\n    align-items: center;\n}\n\n.slide {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    transition: transform 0.5s ease;\n}\n\n.feature-image {\n    width: 25%;\n    margin: auto;\n    margin-right: 20px;\n}\n\n.description {\n    width: 60%;\n    margin-right: 5%;\n}\n\n.slide_arrow {\n    width: 30px;\n    height: 30px;\n    border-color: #000;\n    position: absolute;\n    top: 50%;\n    margin-top: -31px\n}\n\n.prev {\n    border-bottom: 6px solid;\n    border-left: 6px solid;\n    transform: rotate(45deg);\n    left: 10px\n}\n\n\n.next {\n    border-bottom: 6px solid;\n    border-left: 6px solid;\n    transform: rotate(-135deg);\n    right: 10px\n}\n\n\n@media screen and (max-width:1030px) {\n    .feature-div {\n        height: 400px;\n        margin-bottom: 120px;\n    }\n\n    .description {\n        font-size: 14px;\n    }\n}\n\n\n@media screen and (max-width:767px) {\n    .feature-div {\n        height: 300px;\n        margin-bottom: 80px;\n    }\n\n    .description {\n        font-size: 12px;\n    }\n}\n\n@media screen and (max-width:550px) {\n    .feature-div {\n        height: 240px;\n    }\n\n    .description {\n        font-size: 8px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
